export var NodeType;
(function (NodeType) {
    NodeType[NodeType["Menu"] = 1] = "Menu";
    NodeType[NodeType["MenuItem"] = 2] = "MenuItem";
})(NodeType || (NodeType = {}));
export var TreeNodeType;
(function (TreeNodeType) {
    TreeNodeType["MenuAuth"] = "MENU_AUTH";
    TreeNodeType["GroupAuth"] = "GROUP_AUTH";
    TreeNodeType["Menu"] = "MENU";
    TreeNodeType["Group"] = "GROUP";
    TreeNodeType["Module"] = "MODULE";
    TreeNodeType["Model"] = "MODEL";
    TreeNodeType["Page"] = "PAGE";
    TreeNodeType["Process"] = "PROCESS";
    TreeNodeType["ModelData"] = "ModelData";
    TreeNodeType["PageData"] = "PageData";
})(TreeNodeType || (TreeNodeType = {}));
export var AuthType;
(function (AuthType) {
    AuthType[AuthType["Org"] = 1] = "Org";
    AuthType[AuthType["User"] = 2] = "User";
    AuthType[AuthType["Role"] = 3] = "Role";
})(AuthType || (AuthType = {}));
export var OperateType;
(function (OperateType) {
    OperateType[OperateType["Add"] = 0] = "Add";
    OperateType[OperateType["Edit"] = 1] = "Edit";
    OperateType[OperateType["Del"] = 2] = "Del";
})(OperateType || (OperateType = {}));
export var OperateAuthMapping;
(function (OperateAuthMapping) {
    OperateAuthMapping["INSERT"] = "\u65B0\u589E";
    OperateAuthMapping["UPDATE"] = "\u7F16\u8F91";
    OperateAuthMapping["DELETE"] = "\u5220\u9664";
})(OperateAuthMapping || (OperateAuthMapping = {}));
export var AppStatus;
(function (AppStatus) {
    AppStatus["running"] = "\u8FD0\u884C\u4E2D";
    AppStatus["stop"] = "\u505C\u6B62";
    AppStatus["error"] = "\u5F02\u5E38";
    AppStatus["starting"] = "\u542F\u7528\u4E2D";
    AppStatus["updating"] = "\u5347\u7EA7\u4E2D";
    AppStatus["delete"] = "\u5378\u8F7D";
})(AppStatus || (AppStatus = {}));
;
export var AppAutoStatus;
(function (AppAutoStatus) {
    AppAutoStatus[AppAutoStatus["AUTO_PUBLISHED"] = 0] = "AUTO_PUBLISHED";
    AppAutoStatus[AppAutoStatus["MANUAL_PUBLISHED"] = 1] = "MANUAL_PUBLISHED";
    AppAutoStatus[AppAutoStatus["AUTO_UNPUBLISHED"] = 2] = "AUTO_UNPUBLISHED";
    AppAutoStatus[AppAutoStatus["MANUAL_UNPUBLISHED"] = 3] = "MANUAL_UNPUBLISHED";
})(AppAutoStatus || (AppAutoStatus = {}));
;

import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
// import { MandatoryRuleConfig } from '@cloudpivot-hermes/model-designer/lib';
import { namespace } from 'vuex-class';
import { AppAuthService } from '@/sevices';
import Perm from '@/router/permission';
import { AppStatus, AppAutoStatus } from '@/typings/app-auth';
import { Loading } from '@cloudpivot-hermes/common-components';
import { isPC } from '@/common/utils';
const Global = namespace('Global');
let AppDetailHome = class AppDetailHome extends Vue {
    constructor() {
        super(...arguments);
        this.keySwitch = false;
        this.appDetail = {};
        this.pageLoading = false;
        this.flowIsComplete = false;
        this.flowIsSuccess = false;
        this.modelIsComplete = true;
        this.modelIsSuccess = true;
        this.isLoading = false;
        this.appPages = null;
        this.buttonList = [
            {
                title: '权限配置',
                routePath: '/auth-config',
                routeName: 'auth-config',
                icon: 'lock-o',
            },
            // {
            //   title: '流程配置',
            //   routePath: '',
            //   routeName: '',
            //   icon: 'subordinate-o',
            // },
            // {
            //   title: '模型必填规则配置',
            //   routePath: '/model-config',
            //   routeName: 'model-config',
            //   icon: 'frozen-o',
            // },
            // {
            //   title: '模型显示规则配置',
            //   routePath: '',
            //   routeName: '',
            //   icon: 'eye-o',
            // },
            // {
            //   title: '主题配置',
            //   routePath: '',
            //   routeName: '',
            //   icon: 'skin-o',
            // },
            {
                title: '更多功能开发中...',
                routePath: '',
                routeName: '',
                icon: 'ellipsis-o',
            },
        ];
        this.AppStatus = AppStatus;
        this.AppStatusCode = ['stop', 'error', 'starting', 'updating', 'delete'];
        this.AppAutoStatus = AppAutoStatus;
        this.intervalId = null;
        this.hasCodePerm = function () { };
    }
    get isQualifiedCondition() {
        if (isPC())
            return true;
        return false;
    }
    get isComplete() {
        return this.modelIsComplete;
    }
    get isSuccess() {
        return this.modelIsSuccess;
    }
    get appCode() {
        return this.$route.params.appCode;
    }
    get appVersion() {
        return this.$route.params.appVersion;
    }
    get appName() {
        return this.$route.query.appName || this.appDetail.appName || '--';
    }
    get hasOnlineOrOffLinePerm() {
        return this.hasCodePerm(this.$PermCode.appManage.onlineAndOffline);
    }
    switchChange() {
        AppAuthService.updateAppAccess({
            visitType: this.keySwitch,
            appCode: this.appCode,
        }).then((res) => {
            if (res.success) {
                this.setOpenBrowserStatus(this.keySwitch);
                this.$message.success('操作成功');
            }
            else {
                this.$message.error(res.errMessage);
            }
        });
    }
    back() {
        this.$router.push({ name: 'app-installed' });
    }
    goPage(item) {
        if (!item.routePath)
            return;
        this.$router.push({
            name: item.routeName,
            params: {
                appCode: this.appCode,
                appVersion: this.appVersion,
            },
            query: {
                appName: this.appDetail.appName,
            },
        });
    }
    async getAccessType() {
        const res = await AppAuthService.getAppAccessType({ appCode: this.appCode });
        if (res.success) {
            this.keySwitch = res.data;
            this.setOpenBrowserStatus(this.keySwitch);
        }
    }
    async created() {
        this.hasCodePerm = Perm.hasAppCodePerm(this.appCode);
        this.pageLoading = true;
        await this.getAppDetail();
        this.getAccessType();
        this.pageLoading = false;
        // this.checkModel();
        this.getAppPages();
        this.setIntervalGetAppDetail();
    }
    setIntervalGetAppDetail() {
        return new Promise((resolve) => {
            clearInterval(this.intervalId);
            this.intervalId = setInterval(() => {
                this.getAppDetail()
                    .then(() => {
                    resolve('');
                })
                    .catch((e) => {
                    if (e) {
                        clearInterval(this.intervalId);
                    }
                });
            }, 5000);
        });
    }
    beforeDestroy() {
        clearInterval(this.intervalId);
    }
    // checkModel () {
    //   AppAuthService.checkModelRule({ appCode: this.appCode })
    //     .then((res: any) => {
    //       const data: any[] = res.data || [];
    //       this.modelIsSuccess = this.checkModelData(data);
    //     })
    //     .finally(() => {
    //       this.modelIsComplete = true;
    //     });
    // }
    checkModelData(data = []) {
        return data.every((item) => {
            const domainModelVOS = item.domainModelVOS || [];
            return domainModelVOS.every((model) => {
                const mandatoryRuleVOS = model.mandatoryRuleVOS || [];
                if (!mandatoryRuleVOS.length) {
                    return true;
                }
                else {
                    return mandatoryRuleVOS.every((rule) => {
                        return rule.ok;
                    });
                }
            });
        });
    }
    async getAppDetail() {
        const params = {
            appCode: this.appCode,
            appVersion: this.appVersion,
        };
        const res = await AppAuthService.getAppDetail(params);
        if (res.success) {
            this.appDetail = res.data;
        }
        else {
            this.appDetail = {};
        }
    }
    async updatePublished(app) {
        const isPublish = app.publish === 'PUBLISHED';
        const params = {
            appCode: this.appCode,
            appVersion: this.appVersion,
            publishStatus: isPublish ? 'UNPUBLISHED' : 'PUBLISHED',
        };
        const unPstr = '下线后所有用户将无法使用该应用，确定下线吗？';
        let pStr = '确定上线吗？'; // 上线后有权限的用户可以使用该应用，
        let hasPublicPage = false;
        if (!isPublish && this.appPages) {
            const groupVOS = this.appPages?.data?.groupVOS || [];
            hasPublicPage = this.appHasPublicPage(groupVOS);
            if (hasPublicPage) {
                pStr = `该应用包含公开页面，公开页面的访问不受权限控制，包括其全部字段、数据、操作等。${pStr}`;
            }
        }
        this.$confirm({
            title: isPublish ? '下线提示' : '上线提示',
            content: isPublish ? unPstr : pStr,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                AppAuthService.updatePublished(params).then((res) => {
                    if (res.success) {
                        this.getAppDetail();
                    }
                });
            },
        });
    }
    /**
     * 判断是应用否有公开页面
     */
    appHasPublicPage(groupVOS = []) {
        let hasPublicPage = false;
        for (const group of groupVOS) {
            if (this.groupHasPublicPage(group?.pages || [])) {
                hasPublicPage = true;
                break;
            }
        }
        return hasPublicPage;
    }
    /**
     * 判断组是否有公开页面
     */
    groupHasPublicPage(pages = []) {
        let hasPublicPage = false;
        for (const page of pages) {
            if (this.isPublicPage(page)) {
                hasPublicPage = true;
                break;
            }
        }
        return hasPublicPage;
    }
    /**
     * 判断页面是否是公开页面
     */
    isPublicPage(page) {
        let data = page.data || '{}';
        data = JSON.parse(data);
        const designer = data.designer || {};
        const componentName = Object.keys(designer);
        let isPublicPage = false;
        for (const i of componentName) {
            if (designer[i].type === 'Page') {
                isPublicPage = !!(designer[i]?.props?.publicPage);
                break;
            }
        }
        return isPublicPage;
    }
    /**
     * 获取应用的页面信息
     */
    async getAppPages() {
        this.isLoading = true;
        let res = null;
        try {
            res = await AppAuthService.getAppPages({
                appCode: this.appCode,
                version: this.appVersion,
            });
            this.isLoading = false;
        }
        catch (e) {
            console.log(e);
            this.isLoading = false;
        }
        this.appPages = res;
    }
};
__decorate([
    Global.Mutation('setOpenBrowserStatus')
], AppDetailHome.prototype, "setOpenBrowserStatus", void 0);
AppDetailHome = __decorate([
    Component({
        name: 'AppDetailHome',
        components: {
            AppDetail: () => import('./detail'),
            AppImage: () => import('@/components/global/app-image'),
            Loading,
        },
    })
], AppDetailHome);
export default AppDetailHome;
